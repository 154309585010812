import { Injectable } from "@angular/core";
import { environment } from "../../../../../../../environments/environment";
import { BaseService } from "../../../../../../core/services/base.service";
import { ResponseJson } from "../../../../../../shared/models/interfaces";
import { ILocation, ILocationClusters } from "../models/locations.interface";
import { HttpClient } from "@angular/common/http";
import { from, Observable, of, switchMap, map } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class LocationsClusterService extends BaseService {
    apiUrl = environment.apiUrl + '/location-cluster';

    constructor(protected override http: HttpClient) {
        super(http);
    }

    getAllClusterLocations(payload: { skip: number, searchTerm?: string, limit: number }) {
        return this.get<ResponseJson<any>>(`${this.apiUrl}`, { filters: JSON.stringify(payload) });
    }

    getClusterDetails(id: string, searchTerm: string = '') {
        return this.get<ResponseJson<any>>(`${this.apiUrl}/${id}`, {searchTerm});
    }

    addCluster(formData: FormData) {
        return this.post<ResponseJson<any>>(`${this.apiUrl}`, formData);
    }

    processFile(files: FormData): Observable<any> {
        return this.http.post(`${this.apiUrl}/process-locations-sheet`, files, {
            observe: 'response', // Access full response including headers
            responseType: 'blob', // Default responseType is Blob
        }).pipe(
            switchMap(response => {
                const contentType = response.headers.get('Content-Type') || '';
                const body = response.body;
    
                if (!body) {
                    throw new Error('Response body is null');
                }
    
                if (contentType.includes('application/json')) {
                    // Parse JSON from Blob if Content-Type indicates JSON
                    return from(body.text()).pipe(map(text => JSON.parse(text)));
                } else {
                    // Return Blob directly for non-JSON responses
                    return of(body);
                }
            })
        );
    }

    editCluster(data: { locationClusterId: string, data: FormData }) {
        return this.put<ResponseJson<any>>(`${this.apiUrl}`, data);
    }

    downloadAllLocations() {
        return this.get<ResponseJson<any>>(`${this.apiUrl}/download-all-locations`, {}, 'blob');
    }

    deleteLocationCluster(id: ILocation['_id']) {
        return this.delete<ResponseJson<any>>(`${this.apiUrl}/${id}`);
    }

    getLocations(filters:{searchTerm?:string}) {
        return this.get<ResponseJson<any>>(`${this.apiUrl}/locations-dropdown`, { filters: JSON.stringify(filters) });
    }

    getLocationClusterDropdown(searchTerm?: string) {
        return this.get<ResponseJson<any>>(`${this.apiUrl}/cluster-dropdown`, { searchTerm: searchTerm ?? '' });
    }

    validateClusterName(data: Partial<ILocationClusters>) {
        return this.get<ResponseJson<any>>(environment.apiUrl + '/validate/cluster-name', ({ data: JSON.stringify(data) }))
    }

    validateClusterDeletion(locationClusterId: ILocationClusters['_id']) {
        return this.get<ResponseJson<any>>(environment.apiUrl + '/validate/cluster-deletion', ({ data: JSON.stringify({ _id: locationClusterId }) }))
    }
}